<template>
  <section class="app-container" ref="seatStatisticsDetailsRef">
    <FilterLayout @doReset="resetForm" @doSearch="doSearch" ref="filterLayoutRef" @doCollapseEffect="getSize">
      <template #customform>
        <a-form ref="formRef" class="form rms-form" layout="vertical" :model="formState">
          <a-form-item label="Group Lead" name="groupLeadInfo" class="rms-form-item">
            <a-input
                v-model:value="formState.groupLeadInfo"
                placeholder="Name/ID Number"
                allowClear
            />
          </a-form-item>
          <a-form-item label="使用人" name="userInfo" class="rms-form-item">
            <a-input
                v-model:value="formState.userInfo"
                placeholder="Name/ID Number"
                allowClear
            />
          </a-form-item>
          <a-form-item label="座位编号" name="code" class="rms-form-item">
            <a-input
                v-model:value="formState.code"
                placeholder="请输入"
                allowClear
            />
          </a-form-item>
          <a-form-item label="月份" name="Date" class="rms-form-item">
            <a-month-picker :allowClear="false" format="YYYY-MM" class="item" style="width: 100%"
                            v-model:value="formState.Date" placeholder="请选择" />
          </a-form-item>
        </a-form>
      </template>
    </FilterLayout>

    <a-table ref="tableRef" :columns="columns" :data-source="tableData" rowKey="code"
             :scroll="{ y: tableHeight, x: 1000 }" :pagination="pagination">
      <template #action="{ record }">
        <div v-if="record.isSafety === '0'">
        <Tooltip title="设为隔离座位">
          <img src="@/assets/images/Quarantine.png" alt="" @click="setIsolatedSeats(record)" style="width: 1.25vw;height: 1.25vw;cursor: pointer;"/>
        </Tooltip>
        </div>
        <div v-else>
        <Tooltip title="取消隔离">
          <MinusCircleOutlined @click="cancelQuarantine(record)" style="color: #DC5E4F;font-size: 1.25vw;cursor: pointer;"/>
        </Tooltip>
        </div>
      </template>
    </a-table>
    <ModalBox :getContainer="()=>$refs.seatStatisticsDetailsRef" :title="modalTitle" v-model="modelValue" :ok="handleOk" :content="content" />
  </section>
</template>

<script>
import {defineComponent, nextTick, onActivated, onMounted, reactive, ref} from "vue";
import {MinusCircleOutlined} from "@ant-design/icons-vue";
import Tooltip from "@/components/Tooltip.vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import {cloneDeep} from "lodash";
import {useTableHeight} from "@/utils/common";
import moment from "moment/moment";
import {seatFloor} from "@/utils/formatStatus";
import ModalBox from '@/components/ModalBox'
import {message} from 'ant-design-vue'

export default defineComponent({
  name: "index",
  components: {
    Tooltip,
    MinusCircleOutlined,
    ModalBox
  },
  setup() {
    let initData = {
      groupLeadInfo: '',
      code: "",
      userInfo: "",
      uplevel: seatFloor(localStorage.getItem('floor')),
      year: "",
      month: "",
      Date: moment(),
    }
    const { $api } = useGlobalPropertyHook();
    const formState = reactive({...initData});
    const formRef = ref();
    let currentState  = {}
    const tableHeight = ref(0);
    const tableData = ref([])

    const resetForm = () => {
      Object.assign(formState,initData)
      formRef.value.resetFields();
      updateParams()
      doSearch();
    };

    const updateParams =() => {
      currentState = cloneDeep(formState);
    }

    const doSearch = () => {
      pagination.current = 1;
      updateParams()
      getTableData(currentState)
    };
    const resetSearch = () => {
      updateParams()
      getTableData(currentState)
    };

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    const columns = [
      {
        title: "座位编号",
        dataIndex: "code",
        key: "code",
        ellipsis: true,
        width: 100,
      },
      {
        title: "City",
        dataIndex: "city",
        key: "city",
        ellipsis: true,
        width: 80,
      },
      {
        title: "Building",
        dataIndex: "building",
        key: "building",
        ellipsis: true,
        width: 80,
      },
      {
        title: "Floor",
        dataIndex: "floor",
        key: "floor",
        ellipsis: true,
        width: 80,
      },
      {
        title: "预定情况",
        dataIndex: "reservationStatus",
        key: "reservationStatus",
        ellipsis: true,
        width: 80,
      },
      {
        title: "座位申请",
        dataIndex: "seatApplication",
        key: "seatApplication",
        ellipsis: true,
        width: 200,
      },
      {
        title: "使用人",
        dataIndex: "userNameAndEmail",
        key: "userNameAndEmail",
        ellipsis: true,
        width: 150,
        customRender: ({ text }) => (
            <div>
              <div>{text ? text.split("/")[0]: '--'}</div>
              <div>{text ? text.split("/")[1] : null}</div>
            </div>
        ),
        customCell:() => {
          return {
            style: {
              lineHeight: 1.2,
              padding:'6px 10px',
            }
          }
        }
      },
      {
        title: "ID Number",
        dataIndex: "userId",
        key: "userId",
        ellipsis: true,
        width: 80,
        customRender: ({ text }) => (
            <div>{text ? text: '--'}</div>
        ),
      },
      {
        title: "Group Lead",
        dataIndex: "groupLeadNameAndEmail",
        key: "groupLeadNameAndEmail",
        ellipsis: true,
        width: 150,
        customRender: ({ text }) => (
            <div>
              <div>{text ? text.split("/")[0]: '--'}</div>
              <div>{text ? text.split("/")[1] : null}</div>
            </div>
        ),
        customCell:() => {
          return {
            style: {
              lineHeight: 1.2,
              padding:'6px 10px',
            }
          }
        }
      },
      {
        title: "操作",
        dataIndex: "action",
        key: "action",
        slots: { customRender: "action" },
        width: 50,
        fixed: "right",
      },
    ]

    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        getTableData(currentState);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        getTableData(currentState);
      },
    });

    const getTableData = (filterData) => {
      filterData.year = filterData.Date.format("YYYY-MM").split('-')[0]
      filterData.month = filterData.Date.format("YYYY-MM").split('-')[1]
      const params = {
        ...filterData,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
      };
      console.log(params)
      $api.seatUsageDetails(params).then((res) => {
        pagination.total = res.data.total;
        tableData.value = res.data.resource;
      });
    };

    const modalTitle = ref(false)
    const modelValue = ref(false)
    const content = ref('')
    const flag = ref()
    const modalData = ref()
    const setIsolatedSeats = (record) => {
      console.log(record)
      modalTitle.value = '正在设置隔离座位'
      content.value = '该座位将仅限数据涉密相关人员或团队使用。设置后，员工申请安全隔离座位时需要上传审批证明。'
      flag.value = 1
      modalData.value = record.code
      modelValue.value = true
    }
    const cancelQuarantine = (record) => {
      console.log(record)
      modalTitle.value = '正在取消隔离座位'
      content.value = '是否确认取消设置该座位为隔离座位'
      flag.value = 2
      modalData.value = record.code
      modelValue.value = true
    }
    const handleOk = () => {
      if(flag.value===1){
        setSafeZoneSeat(modalData.value)
      }else if(flag.value===2){
        cancelSafeZoneSeat(modalData.value)
      }
    }
    const setSafeZoneSeat = (code)=>{
      $api.setSafeZoneSeat(code).then((res) => {
        if (res.code === 200) {
          message.success(res.msg)
          resetSearch()
        }
      });
    }
    const cancelSafeZoneSeat = (code)=>{
      $api.cancelSafeZoneSeat(code).then((res) => {
        if (res.code === 200) {
          message.success(res.msg)
          resetSearch()
        }
      });
    }

    onMounted(() => {
      doSearch();
      getSize()
    });
    onActivated(() => {

    });
    return {
      formState,
      formRef,
      resetForm,
      doSearch,
      tableHeight,
      getSize,
      columns,
      tableData,
      pagination,
      getTableData,
      setIsolatedSeats,
      cancelQuarantine,
      modalTitle,
      modelValue,
      content,
      handleOk,
      setSafeZoneSeat,
      cancelSafeZoneSeat
    };
  },
});
</script>

<style scoped lang="less">

</style>
